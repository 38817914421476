<script>
import { message } from 'ant-design-vue'
import { ACCESS_TOKEN } from '../../store/mutation-types'
import { postAction } from '@/command/netTool'
import {getToken} from '@/utils/business'

export default {
  data() {
    return {
      select: 0,
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onJump() {
      let aData = JSON.parse(localStorage.getItem('user_info'))
      const { project } = this.item
      const { id } = aData
      if (!['nb_jsc','gxlk_jsc', 'default', 'nbwxlk','wxlk'].includes(project)) {
        postAction('/api/admin/lkapply/isOpen', { appId: project, userId: id }).then((e) => {
          const { code, data } = e
          if (code == 200) {
            if (data !== '1') {
              this.$router.push('/apply?appId=' + project)
            } else {
              if (this.item.href) {
                window.location.href =
                  this.item.href + '?token=' + getToken() + '&appId=' + project
              } else {
                message.error('开发中，敬请期待')
              }
            }
          }
        })
      } else {
        if (this.item.href) {
          window.location.href = this.item.href + '?token=' +  getToken()  + '&appId=' + project
        } else {
          message.error('开发中，敬请期待')
        }
      }
    },
    mouseover() {
      this.select = 1
    },
    mouseout() {
      this.select = 0
    },
  },

  render() {
    return (
      <div class="entrance-main" id="box" onClick={this.onJump} onMouseover={this.mouseover} onMouseout={this.mouseout}>
        <div class="entrance-bk">{this.select == 0 ? <img src={this.item.icon} /> : <img src={this.item.img} />}</div>
        <div>{this.item.name}</div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
.entrance-main {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5vh;
  & > div:last-child {
    font-size: 30px;
    font-family: 迷你简汉真广标;
    font-weight: 400;
    color: #333333;
  }
  &:hover {
    animation: scaleDraw 0.8s ease;
    animation-fill-mode: forwards;
    .entrance-bk {
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    & > div:last-child {
      font-size: 30px;
      font-family: 迷你简汉真广标;
      font-weight: 400;
      color: #2665fa;
    }
  }
}
.entrance-bk {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 70%;
    min-width: 70%;
    -webkit-user-drag: none;
  }
}
</style>
