import config from '@/config/defaultSettings'
import { ACCESS_TOKEN } from '@/store/mutation-types'
/**
 * @description 判断标题是否是共享冰爽爽
 */
export const isShareCoolAndRefreshing = config.title === '共享冰爽爽'

/**
 * @description 获取标题
 */
export const getTitle = () => {
    return config.title
 }

 /**
 * @description 获取token
 */
export const getToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN)||''
    token  = token.replace(/"/g,'')
   token = token.replace(/\//g,'')
   token = token.replace(/\\/g,'')

    return token

}
