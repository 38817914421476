<script>
import EntranceGroup from '@/components/EntranceGroup'
import { getAction } from '@/command/netTool'
import { isShareCoolAndRefreshing } from '../../utils/business'
export default {
  mounted() {
    this.getList()
  },
  data() {
    return {
      current: 1,
      pages: 1,
      list: [],
      limit: 6, //每页显示条数
      //   filterArr: ['驾驶舱', '我享检测', '我享预警', '我享服务', '我享冷库', '我享交易', '系统管理中心'],
    }
  },
  methods: {
    getList() {
      getAction('/api/admin/app/appList').then((e) => {
        // let arr = e.filter((f) => this.filterArr.includes(f.appName))
        this.list = [].concat(e)
        this.pages = Math.ceil(this.list.length / this.limit)
      })
    },

    renderPageData() {
      let start = (this.current - 1) * this.limit
      if (start >= this.list.length) start = 0
      let end = this.current * this.limit
      if (end >= this.list.length) end = this.list.length
      return this.list.slice(start, end)
    },
    renderIconGroup() {
      return (
        <EntranceGroup
          data={this.renderPageData().map((e) => ({
            name: e.appName,
            icon: e.appIcon,
            img: e.appImg,
            href: e.appUrl,
            project: e.appCode,
          }))}
        />
      )
    },
    renderContentFoot() {
      //   const data = [
      //     {
      //       img: require('./asset/focus.png'),
      //       display: this.current === 1,
      //       roate: 0,
      //       onClick: () => {
      //         if (this.current === 1) return
      //         this.current--
      //       },
      //     },
      //     {
      //       img: require('./asset/normal.png'),
      //       display: this.current !== 1,
      //       roate: 180,
      //       onClick: () => {
      //         if (this.current === 1) return
      //         this.current--
      //       },
      //     },
      //     {
      //       img: require('./asset/focus.png'),
      //       display: this.current === this.pages,
      //       roate: 180,
      //       onClick: () => {
      //         if (this.current === this.pages) return
      //         this.current++
      //       },
      //     },
      //     {
      //       img: require('./asset/normal.png'),
      //       display: this.current !== this.pages,
      //       roate: 0,
      //       onClick: () => {
      //         if (this.current === this.pages) return
      //         this.current++
      //       },
      //     },
      //   ].filter((e) => e.display)

      const onClick = (current, index) => {
        this.current = index
      }

      return (
        <div class={'content-foot'}>
          {[...new Array(this.pages).fill('')].map((e, i) => {
            return (
              <img
                src={this.current == i + 1 ? require('./asset/focus.png') : require('./asset/normal.png')}
                // style={{ width: '14px', height: '14px', transform: `rotate(${e.roate || 0}deg)` }}
                style={{ width: '14px', height: '14px', marginRight: '10px' }}
                onClick={() => onClick(this.current, i + 1)}
              />
            )
          })}
        </div>
      )
    },
    renderContent() {
      return <div class="index-content">{this.renderContentFoot()}</div>
    },
    renderBody() {
      return <div class="index-body">{this.renderIconGroup()}</div>
    },
    renderHeader() {
      return (
        <div
          class="index-header"
          style={{
            backgroundImage: `url(${isShareCoolAndRefreshing ? require('./asset/bg.png') : require('./asset/bg2.png')})`,
          }}
        >
          {this.renderBody()}
          {this.pages > 1 && this.renderContent()}
        </div>
      )
    },
    returnConsole() {
      this.$router.push('/index')
    },
    renderConsole() {
      return (
        <div class="index-console">
          <a-button type="primary" onClick={this.returnConsole} class="consoleBox">
            <a-icon type="slack" class="consoleBox-icon" />
            控制台
          </a-button>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="index-box">
        <div class="index-main">{this.renderHeader()}</div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.index-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.index-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.index-console {
  position: absolute;
  right: 0px;
  top: 20px;

  .consoleBox {
    font-size: 16px;
    border-radius: 4px;
    margin-right: 20px;
    font-weight: bold;
    height: 40px;
    background-color: #2665fa;
    border: 0;

    .consoleBox-icon {
      font-size: 20px;
    }
  }
}

.index-header {
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  position: relative;
}

.index-body {
  width: calc(100% - 806px);
  height: 467px;
  position: absolute;
  top: 180px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.index-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: absolute;
  bottom: 14%;
}

.content-foot {
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    cursor: pointer;
    -webkit-user-drag: none;
  }
  //   & > img:last-child {
  //     margin-left: 8px;
  //   }
}
</style>
